import React, { Fragment } from "react"

import { IconContext } from "react-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "40 things"

    return (
      <IconContext.Provider value={{ color: "gray", size: "1em" }}>
        <Layout isHome={true} location={this.props.location} title={siteTitle}>
          <SEO
            title="Home"
            keywords={[
              `blog`,
              `40`,
              `40 things`,
              `life`,
              `quality`,
              `experiences`,
            ]}
          />
        </Layout>
      </IconContext.Provider>
    )
  }
}

export default IndexPage
